import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Masthead from '../components/masthead/masthead'
import TeamListing from '../components/teamlisting/teamlisting'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

export const query = graphql`
  {
    prismic {
      allAboutuss {
        edges {
          node {
            metatitle
            metadescription
            summary
            teammembers {
              teammemberimage
              teammemberjobtitle
              teammemberlinkedin {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
              teammembername
            }
            teammemberstitle
            title
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const doc = data.prismic.allAboutuss.edges.slice(0, 1).pop()
  if (!doc) return null
  const title = RichText.asText(doc.node.title)
  const metaTitle = doc.node.metatitle ? RichText.asText(doc.node.metatitle) : title
  const metaDescription = RichText.asText(doc.node.metadescription)
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname="/about-us"
      />
      <Masthead title={title} cssClass="c-masthead--condensed"></Masthead>
      <TeamListing doc={doc} />
    </Layout>
  )
}
