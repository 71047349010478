import React from 'react'
import 'intersection-observer'
import IsVisible from 'react-is-visible'
import { RichText } from 'prismic-reactjs'

class TeamListing extends React.Component {
  render () {
    const doc = this.props.doc.node
    return (
      <div className="c-team-listing">
        <div className="c-team-listing__header-container">
          <div className="c-team-listing__particles-container">
            <video muted loop playsInline autoPlay className="c-team-listing__particles-video">
              <source src="/videos/particles.webm" type='video/webm;codecs="vp8, vorbis"'/>
              <source src="/videos/particles.mp4" type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="c-team-listing__header">
                <div className="row">
                  <div className="c-team-listing__header-content">
                    <p className="c-team-listing__header-content-wrapper">{RichText.asText(doc.summary)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="c-team-listing__list-header">
              <h2 className="c-team-listing__list-header-title">{RichText.asText(doc.teammemberstitle)}</h2>
            </div>
          </div>
          <div className="row">
            {doc.teammembers.map((teammember, index) => {
              if (teammember === null) return null
              return (
                <div className="c-team-listing__list-item" key={`teammember-${ index }`}>
                  <div className="c-team-listing__list-wrapper">
                    <IsVisible once>
                      {isVisible =>
                        <div className={`c-team-listing__list-image-container${ isVisible ? ' is-visible' : '' }`}>
                          {teammember.teammemberimage && (
                            <div className="c-team-listing__list-image">
                              <img src={ teammember.teammemberimage.low.url } data-src={ teammember.teammemberimage.url } className="lazyload" alt=""/>
                            </div>
                          )}
                        </div>
                      }
                    </IsVisible>
                    <h3 className="c-team-listing__list-name">{RichText.asText(teammember.teammembername)}</h3>
                    <p className="c-team-listing__list-job">{RichText.asText(teammember.teammemberjobtitle)}</p>
                    {teammember.teammemberlinkedin && (
                      <a href={teammember.teammemberlinkedin.url} className="c-team-listing__list-link" target="_blank" rel="noopener">
                        {/* eslint-disable */}
                        <svg data-name="Group 1282" viewBox="0 0 76.624 65.326"><path data-name="Path 2525" d="M72.864 61.109a1.2 1.2 0 001.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025 1.291 2.029h.724l-1.389-2.1zm-.783-.472h-.785v-1.593h.995c.514 0 1.1.084 1.1.757 0 .774-.593.836-1.314.836" fill="#0a66c2"/><path data-name="Path 2520" d="M55.204 55.204h-9.6V40.17c0-3.585-.064-8.2-4.993-8.2-5 0-5.765 3.906-5.765 7.939v15.294h-9.6V24.287h9.216v4.225h.129a10.1 10.1 0 019.093-4.994c9.73 0 11.524 6.4 11.524 14.726zm-40.79-35.143a5.571 5.571 0 115.57-5.572 5.571 5.571 0 01-5.57 5.572m4.8 35.143h-9.61V24.287h9.61zM59.99.004H4.78a4.728 4.728 0 00-4.781 4.67v55.439a4.731 4.731 0 004.781 4.675h55.21a4.741 4.741 0 004.8-4.675V4.67A4.738 4.738 0 0059.99 0" fill="#0a66c2"/><path data-name="Path 2526" d="M72.163 56.411a4.418 4.418 0 10.085 0h-.085m0 8.33a3.874 3.874 0 113.809-3.938v.065a3.791 3.791 0 01-3.708 3.871h-.1" fill="#0a66c2"/></svg>
                        {/* eslint-enable */}
                        <span className="u-visually-hide">LinkedIn</span>
                      </a>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default TeamListing
